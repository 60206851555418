<template>
    <div class="p-8 text-xs">
        <div class="bg-white shadow rounded-lg p-4">
            <div class="flex flex-col md:flex-row justify-between items-center mb-4">
                <div class="flex items-center mb-2 md:mb-0">
                    <button class="bg-red-500 text-white px-2 py-1 rounded mr-2">new</button>
                    <input type="text" placeholder="Please select query"
                        class="border border-gray-300 rounded px-2 py-1 w-full md:w-64">
                </div>
                <button class="text-red-500">Close All</button>
            </div>
            <div class="flex flex-col md:flex-row items-center mb-4">
                <div class="flex items-center mb-2 md:mb-0">
                    <button class="text-gray-500 mr-2"><i class="fas fa-pencil-alt"></i></button>
                    <button class="text-gray-500 mr-2"><i class="fas fa-envelope"></i></button>
                    <button class="text-gray-500 mr-2"><i class="fas fa-download"></i></button>
                    <button class="text-gray-500 mr-2"><i class="fas fa-upload"></i></button>
                    <button class="text-gray-500 mr-2"><i class="fas fa-sync-alt"></i></button>
                </div>
                <input type="text" placeholder="ICCID/IMEI/MSISDN"
                    class="border border-gray-300 rounded px-2 py-1 w-full md:w-64 ml-auto">
                <button class="text-red-500 ml-2">bulk search</button>
            </div>
            <div class="overflow-x-auto">
                <table class="min-w-full bg-white">
                    <thead>
                        <tr class="w-full bg-gray-100">
                            <th class="py-2 px-4 border-b border-gray-200"><input type="checkbox"></th>
                            <th class="py-2 px-4 border-b border-gray-200">Tags</th>
                            <th class="py-2 px-4 border-b border-gray-200">ICCID</th>
                            <th class="py-2 px-4 border-b border-gray-200">MSISDN</th>
                            <th class="py-2 px-4 border-b border-gray-200">Status</th>
                            <th class="py-2 px-4 border-b border-gray-200">Cycle Usage</th>
                            <th class="py-2 px-4 border-b border-gray-200">Limit</th>
                            <th class="py-2 px-4 border-b border-gray-200">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="simCard in simCards" :key="simCard.iccid" class="bg-white">
                            <td class="py-2 px-4 border-b border-gray-200"><input type="checkbox"></td>
                            <td class="py-2 px-4 border-b border-gray-200"><button
                                    class="border border-gray-300 rounded px-2 py-1">+ Tag</button></td>
                            <td class="py-2 px-4 border-b border-gray-200">
                                <router-link :to="'/simcard/detail/' + simCard.iccid" class="text-red-500">
                                    {{ simCard.iccid }}
                                </router-link>
                            </td>
                            <td class="py-2 px-4 border-b border-gray-200">{{ simCard.msisdn }}</td>
                            <td class="py-2 px-4 border-b border-gray-200">
                                <span :class="{'text-blue-500': simCard.status === 'Pre-activated', 'text-green-500': simCard.status === 'Active'}">
                                    • {{ simCard.status }}
                                </span>
                                <label class="toggle-switch" v-if="simCard.status === 'Active'">
                                    <input type="checkbox" v-model="simCard.active">
                                    <span class="slider"></span>
                                </label>
                            </td>
                            <td class="py-2 px-4 border-b border-gray-200">{{ simCard.cycleUsage }}MB</td>
                            <td class="py-2 px-4 border-b border-gray-200">{{ simCard.limit }}</td>
                            <td class="py-2 px-4 border-b border-gray-200">
                                <i class="fas fa-envelope text-gray-500"></i>
                                <i class="fas fa-sync-alt text-gray-500"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="flex justify-between items-center mt-4">
                <div>1 - 10 of 25 items</div>
                <div class="flex items-center space-x-2">
                    <button class="bg-gray-200 px-2 py-1 rounded">&lt;</button>
                    <button class="bg-red-500 text-white px-2 py-1 rounded">1</button>
                    <button class="bg-gray-200 px-2 py-1 rounded">2</button>
                    <button class="bg-gray-200 px-2 py-1 rounded">3</button>
                    <button class="bg-gray-200 px-2 py-1 rounded">&gt;</button>
                    <select class="border border-gray-300 rounded px-2 py-1">
                        <option>10 / page</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active in <2.1.8 */
    {
    opacity: 0;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 20px;
}

.toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #f56565;
}

input:checked+.slider:before {
    transform: translateX(14px);
}
</style>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueApexCharts from 'vue-apexcharts';
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global';
import deleteComponent from '../../components/global/deleteComponent.vue';
import priorityComponent from '../../components/global/prioritystatusComponent.vue';
import statusComponent from '../../components/global/tiketstatusComponent.vue';
import processComponent from '../../components/global/process_statusComponent.vue';

export default {
    data: () => ({
        data: [],
        totalRows: 1,
        perPage: 10,
        limit: 8,
        startNumber: 1,
        endNumber: 8,
        currentPage: 1,
        keyword: '',
        status_process_name: '', // Pastikan diinisialisasi dengan nilai default
        // ticketData: {
        //     id:'',
        //     status_process_id:'',
        // },
        ticket_statuses_id: null,
        priority_id: null, // New data property for priority level
        selectedTicketId: null, // Ensure this is defined
        modal_dialog: false, // delete modal
        isPriorityModalOpen: false, // Add this property to manage modal visibility
        isStatusModalOpen: false,
        isProcessModalOpen: false,
        showStatusModal: false,
        showProcessModal: false,
        delete_id: '',
        default_url: process.env.VUE_APP_IMAGES,
        openDropdownId: null,
        isLoading: false, // To manage loading state
        isDetailModalOpen: false, // To manage detail modal visibility
        selectedTicketDetails: [], // To hold the details of the open tickets
        simCards: [
                { iccid: '8944501812232478951', msisdn: '882360026278071', status: 'Pre-activated', cycleUsage: '0.00', limit: '600MB / Year', active: false },
                { iccid: '8944501812232479017', msisdn: '882360026278077', status: 'Active', cycleUsage: '44.29', limit: '600MB / Year', active: true },
                { iccid: '8944501812232478985', msisdn: '882360026278074', status: 'Pre-activated', cycleUsage: '0.00', limit: '600MB / Year', active: false },
                { iccid: '8944501812232478977', msisdn: '882360026278073', status: 'Active', cycleUsage: '9.67', limit: '600MB / Year', active: true }
            ],
        counts: {
            open: 0,
            close: 0,
            onProgress: 0,
            solved: 0,
            low: 0,
            medium: 0,
            high: 0,
            critical: 0,
        },
        priorityOptions: {
            chart: {
                type: 'pie',
            },
            labels: ['Low', 'High'],
            colors: ['#00a8ff', '#9c88ff'], // Distinct colors for each
        },
        priorityData: [0, 4, 15, 1, 0], // Default values
        statusOptions: {
            chart: {
                type: 'donut',
            },
            labels: ['On Progress', 'Solved'],
            colors: ['#1E90FF', '#FFA500', '#28a745'],
        },
        statusData: [15, 25, 35], // Replace with actual data
        statustiketOptions: {
            chart: {
                type: 'donut',
            },
            labels: ['Open Ticket', 'Close Ticket'],
            colors: ['#1E90FF', '#FFA500', '#28a745'],
        },
        statustiketData: [15, 25, 35], // Replace with actual data
    }),
    mixins: [globalMixin],

    methods: {
        ...mapActions([]),
        getData() {
            this.axios.get('v1/tiket?page=' + this.currentPage + `&limit=${this.limit}&keyword=${this.keyword}`, this.config)
                .then((ress) => {
                    this.data = ress.data.rows;
                    this.totalRows = ress.data.count;
                    console.log(ress.data.counts);

                    // Update counts and priorityData
                    if (ress.data.counts) {
                        this.counts.open = ress.data.counts.open;
                        this.counts.close = ress.data.counts.close;
                        // console.log('Open count:', this.counts.open);
                        this.counts.onProgress = ress.data.counts.onProgress;
                        this.counts.solved = ress.data.counts.solved;
                        this.counts.low = ress.data.counts.low;
                        this.counts.medium = ress.data.counts.medium;
                        this.counts.high = ress.data.counts.high;
                        this.counts.critical = ress.data.counts.critical;
                    } else {
                        console.warn('status_counts is undefined');
                    }
                    this.statusData = [
                        ress.data.counts.onProgress,
                        ress.data.counts.solved,
                    ];

                    this.statustiketData = [
                        ress.data.counts.open,
                        ress.data.counts.close,
                    ];

                    this.priorityData = [
                        ress.data.counts.low,
                        ress.data.counts.high
                    ];
                })
                .catch((err) => {
                    this.errorResponse(err);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        selectTicket(ticket) {
            this.selectedTicketId = ticket.id; // Set the selected ticket ID
            this.status_process_id = ticket.status_process_id; // Optional: set the process status if needed
        },
        async fetchTicketData() {
            try {
                const response = await axios.get(`v1/tiket/${this.$route.params.id}`);
                this.ticketData = response.data;
                this.selectedTicketId = response.data.id; // Set the selected ticket ID
            } catch (error) {
                this.$snack.error({
                    text: 'Failed to fetch ticket data'
                });
            }
        },
        async updateProcessStatus() {
            let formData = new FormData();
            formData.append('status_process_id', this.status_process_id);
            formData.append('ticket_statuses_id', this.status_process_id);

            // Otomatis update ticket_statuses_id ke 'close' jika status_process_id = 2 (Solve)
            if (this.status_process_id === 2) {
                formData.append('ticket_statuses_id', 2); // 2 = Close
            }

            // Tambahkan kondisi untuk memastikan jika status_process_id = 1, tetap bernilai 1
            if (this.status_process_id === 1) {
                formData.set('status_process_id', 1); // Tetap set status_process_id ke 1
                formData.set('ticket_statuses_id', 1); // Tetap set ticket_statuses_id ke 1
            }

            try {
                // Update status proses
                await axios.put(`v1/tiket/ubahStatusProses/${this.selectedTicketId}`, formData, this.config);
                this.$snack.success({
                    text: 'Status proses tiket diperbarui!'
                });
                this.moveTicketToTop(this.selectedTicketId); // Pindahkan tiket ke posisi paling atas
                this.closeProcessModal(); // Pastikan ini menutup modal proses
                this.refreshData(); // Refresh data setelah update

                // Update status tiket
                await axios.put(`v1/tiket/ubahStatusTiket/${this.selectedTicketId}`, formData, this.config);
                // this.$snack.success({
                //     text: 'Status tiket berhasil diperbarui!'
                // });
                this.moveTicketToTop(this.selectedTicketId); // Pindahkan tiket ke posisi paling atas
                this.closeStatusModal(); // Pastikan ini menutup modal status
                this.refreshData(); // Refresh data setelah update

            } catch (error) {
                if (error.response && error.response.status === 400) {
                    this.$refs.form.setErrors(error.response.data.message);
                }
                this.$snack.error({
                    text: 'Gagal memperbarui status proses tiket'
                });
            }
        },
        async updateStatusTicket() {
            if (!this.selectedTicketId) {
                console.error('selectedTicketId is not set');
                this.$snack.error({
                    text: 'Cannot update: No ticket ID specified'
                });
                return; // Exit early
            }

            let formData = new FormData();
            formData.append('ticket_statuses_id', this.ticket_statuses_id); // Append selected status

            try {
                const response = await axios.put(`v1/tiket/ubahStatusTiket/${this.selectedTicketId}`, formData, this.config);
                this.$snack.success({
                    text: 'Status tiket berhasil diperbarui!'
                });
                this.moveTicketToTop(this.selectedTicketId); // Pindahkan tiket ke posisi paling atas
                this.closeStatusModal(); // Pastikan ini menutup modal status, bukan modal proses
                this.refreshData(); // Refresh the data after update
            } catch (error) {
                console.error("Error response:", error);
                if (error.response && error.response.status === 400) {
                    this.$refs.form.setErrors(error.response.data.message);
                }
                this.$snack.error({
                    text: 'Gagal memperbarui status tiket'
                });
            }
        },
        async updatePriorityTicket() {
            if (!this.selectedTicketId) {
                console.error('selectedTicketId is not set');
                this.$snack.error({
                    text: 'Cannot update: No ticket ID specified'
                });
                return; // Exit early
            }

            let formData = new FormData();
            formData.append('priority_id', this.priority_id); // Append selected priority level

            try {
                const response = await axios.put(`v1/tiket/ubahStatusPriority/${this.selectedTicketId}`, formData, this.config);
                this.$snack.success({
                    text: 'Prioritas tiket berhasil diperbarui!'
                });
                this.moveTicketToTop(this.selectedTicketId); // Pindahkan tiket ke posisi paling atas
                this.closePriorityModal(); // Close the modal after update
                this.refreshData(); // Refresh the data after update
            } catch (error) {
                console.error("Error response:", error);
                if (error.response && error.response.status === 400) {
                    this.$refs.form.setErrors(error.response.data.message);
                }
                this.$snack.error({
                    text: 'Gagal memperbarui prioritas tiket'
                });
            }
        },
        refreshData() {
            this.isLoading = true;
            this.getData();
            setTimeout(() => {
                this.isLoading = false; // Simulating loading time, remove or adjust based on actual data load
            }, 1000);
        },
        openDetailModal() {
            // console.log(this.data.filter());
            this.currentDetailPage = 1; // Reset to the first page when opening the modal
            console.log(this.isDetailModalOpen);
            this.isDetailModalOpen = true;
        },
        closeDetailModal() {
            this.isDetailModalOpen = false;
            this.selectedTicketDetails = [];
        },
        openDelete(id) {
            this.delete_id = id;
            this.closeModal();
        },
        showPriorityModal(id) {
            this.selectedTicketId = id;
            this.priority_level = null; // Reset priority when opening the modal
            this.isPriorityModalOpen = true;
        },
        openStatusModal(id) {
            this.selectedTicketId = id; // Set the selected ticket ID
            this.ticket_statuses_id = null; // Reset status when opening the modal
            this.isStatusModalOpen = true; // Show the modal
        },
        openProcessModal(id) {
            this.selectedTicketId = id; // Set the selected ticket ID
            this.status_process_id = null; // Reset status when opening the modal
            this.ticket_statuses_id = null
            this.isProcessModalOpen = true; // Show the modal
            console.log("Selected Ticket ID:", this.selectedTicketId); // Tambahkan log ini untuk debug
        },
        closePriorityModal() {
            this.isPriorityModalOpen = false;
        },
        closeProcessModal() {
            this.isProcessModalOpen = false;
        },
        closeStatusModal() {
            this.isStatusModalOpen = false;
        },
        closeDropdown() {
            this.openDropdownId = false;
        },
        deleteData() {
            this.axios.delete('v1/tiket/' + this.delete_id, this.config)
                .then((ress) => {
                    this.closeModal();
                    this.getData();
                    this.success({
                        text: ress.data.message,
                    });
                });
        },
        ubahStatus() {
            this.axios.put('v1/tiket/' + this.$route.params.id, data, this.config)
                .then((ress) => {
                    this.closeStatusModal();
                    this.getData();
                    this.$snack.success({
                        text: ress.data.message,
                    });
                });
        },
        ubahProcess() {
            this.axios.put('v1/tiket/' + this.$route.params.id, data, this.config)
                .then((ress) => {
                    this.closeProcessModal();
                    this.getData();
                    this.$snack.success({
                        text: ress.data.message,
                    });
                });
        },
        closeModal() {
            this.modal_dialog = !this.modal_dialog;
        },
        getStatusBadge(status) {
            switch (status.toLowerCase()) {
                case 'open':
                    return 'inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10';
                case 'closed':
                    return 'inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10';
                default:
                    return '';
            }
        },
        toggleDropdown(id) {
            this.openDropdownId = this.openDropdownId === id ? null : id;
        },
        isDropdownOpen(id) {
            return this.openDropdownId === id;
        },
        handleClickOutside(event) {
            // Jika dropdown terbuka dan klik di luar elemen dropdown, tutup dropdown
            if (this.openDropdownId && !event.target.closest('.relative.inline-block.text-left')) {
                this.openDropdownId = null;
            }
        },
        moveTicketToTop(ticketId) {
            const ticketIndex = this.data.findIndex(ticket => ticket.id === ticketId);
            if (ticketIndex !== -1) {
                const [ticket] = this.data.splice(ticketIndex, 1); // Hapus tiket dari daftar
                this.data.unshift(ticket); // Tambahkan tiket ke posisi pertama
            }
        },
        getProcessStatusBadge(status) {
            switch (status.toLowerCase()) {
                case 'on progress':
                    return 'inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20';
                case 'solved':
                    return 'inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20';
                case 'pending':
                    return 'inline-flex items-center rounded-md bg-pink-50 px-2 py-1 text-xs font-medium text-pink-700 ring-1 ring-inset ring-pink-700/10';
                case 'hold':
                    return 'inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10';
                case 'assigned':
                    return 'inline-flex items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10';
                default:
                    return '';
            }
        },
        getPriorityBadge(priority) {
            switch (priority.toLowerCase()) {
                case 'low':
                    return 'inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-800';
                case 'medium':
                    return 'inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800';
                case 'high':
                    return 'inline-flex items-center rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-800';
                case 'critical':
                    return 'inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-800';
                default:
                    return '';
            }
        }
    },
    mounted() {
        // Tambahkan event listener untuk klik di luar dropdown
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeDestroy() {
        // Hapus event listener ketika komponen di-destroy
        document.removeEventListener('click', this.handleClickOutside);
    },
    computed: {
        ...mapGetters({
            token: 'auth/token',
            role_id: 'auth/role_id'
        }),
        role_id() {
            return this.$store.state.auth.user.role_id; // Directly access role_id from Vuex state
        },
    },
    created() {
        this.getData();
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        deleteComponent,
        apexChart: VueApexCharts,
        openStatusModal: statusComponent,
        openProcessModal: processComponent,
        openPriorityModal: priorityComponent,
    }
}
</script>
